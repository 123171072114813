import parse from 'html-react-parser';
import type { DOMNode } from 'html-react-parser';

import { getRewrittenUrl } from '../../../analytics-library/entry';
import type { Site } from '../../modules/sites/types/Site';
import type { Deal } from '../../types/Deal';
import { isElement } from '../../utils/isElement';

export default (
  image: string | undefined,
  widgetIntroduction: string,
  articleUrl: string,
  site: Site,
  territory: string,
  articleName: string,
): Deal => {
  const deal: Deal = {
    an: '',
    bundle_models: [],
    click_count: 0,
    id: 0,
    label_image: null,
    last_update: null,
    match_id: 0,
    merchant: {
      id: 0,
      name: '',
      url: '',
      logo_url: '',
      territory,
      preference: 0,
    },
    mobile_match_score: 0,
    model: '',
    model_id: 0,
    model_image: null,
    offer: {
      name: '',
      price: '',
      in_stock: true,
      stock_quantity: null,
      currency_iso: '',
      currency_symbol: '',
      link: '',
      link_text: '',
      merchant_link_text: '',
      label: '',
      percentage_saving: null,
      percentage_saving_label: null,
      money_saving: null,
      money_saving_label: null,
      display_name: '',
      display_labels: '',
    },
    percentage: 0,
    popularity: 0,
    product_key: '',
    product_type: 0,
    score: 0,
    shipping: {
      prime: false,
      url: null,
    },
    image: image || '',
    model_matched: ['Everything'],
  };

  parse(widgetIntroduction, {
    replace: (domNode: DOMNode) => {
      if (!deal?.offer?.link && isElement(domNode) && domNode?.name === 'a') {
        deal.offer.rawLink = (domNode.attribs ?? {}).href;

        deal.offer.link = getRewrittenUrl(
          deal.offer.rawLink,
          site && site.analyticsName,
          territory,
          domNode.attribs['data-custom-tracking-id'],
          articleUrl,
          articleName,
        );
      }
    },
  });

  return deal;
};
