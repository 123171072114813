import React, { useEffect, useState } from 'react';

import { ClickType } from '../../../../sharedModules/getClickType';
import type { Event } from '../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../types/FreyrEventPrefix';
import getImageUrl from '../../../modules/getImageUrl';
import getSwitcherItem from '../../../modules/getSwitcherItem';
import type { SwitcherItem } from '../../../types/SwitcherItem';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/switcher.css';

interface Props {
  items: SwitcherItem[];
  scrollLeft: () => void;
  scrollRight: () => void;
  children?: JSX.Element;
  className?: string;
  selectedIndex: number;
}

const Switcher = ({
  items,
  scrollLeft,
  scrollRight,
  children,
  className,
  selectedIndex,
}: Props): JSX.Element => {
  const {
    sendAnalytics,
    translate,
    genericSharedComponents: { LazyImage },
  } = useHawkWidgetContext();
  const [selectedItem, setSelectedItem] = useState(
    items && items[0]
      ? items[0]
      : {
          type: '',
          value: '',
          label: '',
        },
  );

  useEffect(() => {
    if ((items || []).length > 0) {
      setSelectedItem(items[selectedIndex]);
    }
  }, [items, setSelectedItem, selectedIndex]);

  const scrollLeftHandler = (): void => {
    if (items && items.length > 0) {
      // Use scroll left function is provided to support changing the switcher on a widget level
      if (scrollLeft) {
        scrollLeft();
      } else {
        setSelectedItem(getSwitcherItem('left', items, selectedItem));
      }

      if (sendAnalytics) {
        sendAnalytics({
          label: `${selectedItem.type}:${selectedItem.value}`,
          clickType: ClickType.SWITCHER,
          prefix: FreyrEventPrefix.CLICK_FROM,
        } as Event); // TODO: remove after we check sendAnalytics type in fie-analytics
      }
    }
  };

  const scrollRightHandler = (): void => {
    if (items && items.length > 0) {
      // Use scroll right function is provided to support changing the switcher on a widget level
      if (scrollRight) {
        scrollRight();
      } else {
        setSelectedItem(getSwitcherItem('right', items, selectedItem));
      }

      if (sendAnalytics) {
        sendAnalytics({
          label: `${selectedItem.type}:${selectedItem.value}`,
          clickType: ClickType.SWITCHER,
          prefix: FreyrEventPrefix.CLICK_FROM,
        } as Event); // TODO: remove after we check sendAnalytics type in fie-analytics
      }
    }
  };

  const containerStyle = className && styles[className] ? className : 'container';

  return (
    <div className={styles[containerStyle]}>
      <div
        className={styles['image-container']}
        onClick={scrollLeftHandler}
        role="button"
        tabIndex={0}
        onKeyDown={scrollLeftHandler}
      >
        <LazyImage
          {...{
            src: getImageUrl('misc/pink_arrow_right.png'),
            alt: translate('arrowAltText'),
            width: 10,
            height: 15,
            className: 'switcher-left',
          }}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.text}>
          <span className={styles.label}>{selectedItem.label}</span>
          <strong className={styles.value}>{selectedItem.value}</strong>
        </div>
        {children}
      </div>
      <div
        className={styles['image-container']}
        onClick={scrollRightHandler}
        role="button"
        tabIndex={0}
        onKeyDown={scrollRightHandler}
      >
        <LazyImage
          {...{
            src: getImageUrl('misc/pink_arrow_right.png'),
            alt: translate('arrowAltText'),
            width: 10,
            height: 15,
            className: 'switcher-right',
          }}
        />
      </div>
    </div>
  );
};

export default Switcher;
